/**
 * get Wikidata content as string
 * @param unique q Wikidata content identifier
 * @return Wikidata content as string
 */
export async function getWikidata(q) {
    //console.log('request:getWikidata() q: ' + q);
    const address = 'https://www.wikidata.org/w/rest.php/wikibase/v0/entities/items/' + q;
    //console.log('request:getWikidata() address: ' + address)
    const objct = await get(address);
    ////console.log('request:getWikidata() objct: ' + JSON.stringify(objct));
    //const strng = JSON.stringify(objct);
    ////console.log('request:getWikidata() strng: ' + strng);
    //return strng;
    return objct
};

/**
 * http get request
 *
 * @param pth path
 * @return response as JSON data
 */
export async function get(pth) {
    //console.log('request:get() pth: ' + pth);
    const data = await fetch(pth, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const objct = await data.json();
    return objct;
};

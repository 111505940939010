import React from 'react'
import { Link} from 'react-router-dom';

export default function Header(){

    return (
	<>
	    <Link
	    to='/'
	    >
		<button>
		    Home
		</button>
	    </Link>
	    <Link
	    to='https://www.swingbe.de/imprint/'
	    >
		<button>
		    Imprint
		</button>
	    </Link>
	    <Link
	    to='https://www.swingbe.de/privacy-policy/'
	    >
		<button>
		    Privacy Policy
		</button>
	    </Link>
	</>
    );
};

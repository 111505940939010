import React from 'react';
import PropTypes from 'prop-types';
import {MapContainer,Polyline,TileLayer} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

/*JS module import (vs cdn or style link)*/
import 'leaflet/dist/leaflet.css'
import './entities-map.css';

import EntitiesMarker from './entities-marker';

export default function EntitiesMap({entities}) {
    /*lat and lon of Braunschweig,DE*/
    //const position = [52.26594, 10.52673];
    /*center map in the middle of RVB*/
    const position = [52.16594, 10.52673];
    if ( entities !== undefined && entities !== null && entities.length > 0 ) {
	/*return a React element*/
    return (
	<>
	    <MapContainer
		center={position}
		zoom={9}
		minZoom={4}
		maxZoom={18}
		maxBounds={[[-60, -180], [85, 180]]}
		scrollWheelZoom={false}
	    >

		<TileLayer
		    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		/>

		<MarkerClusterGroup
		    chunkedLoading
		    >
		{
		    entities.map( function( value, key ) {
			if ( value.type === "node"){
			    return (
				<EntitiesMarker
				    key={value.id}
				    index={value.id}
				    id={value.id}
				    lat={value.lat}
				    lon={value.lon}
				    name={value.tags.name}
				/>
			    );
			} else if ( value.type === "way" || value.type === "relation" ) {
			return <EntitiesMarker
				   key={value.id}
				   index={value.id}
				   id={value.id}
				   lat={value.center.lat}
				   lon={value.center.lon}
				   name={value.tags.name}
			       />;
			} else {
			    console.error("entities-map: OSM type (" + value.type + ") NOT known");
			}
                    })
		}
		</MarkerClusterGroup>
	    </MapContainer>
	</>
    );
    }else{
	return (
            <>
		<p>Loading...</p>
            </>
	);
    }
}
EntitiesMap.propTypes = {
    entities: PropTypes.array
};

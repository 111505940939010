import React from 'react'
import PropTypes from 'prop-types';

import FetchOsm from '../components/fetch-osm';

export default function EntityOsm({address, title}) {
    return(
	<>
            <FetchOsm
		address={address}
		title={title}
	    />
	</>
    );
};
EntityOsm.propTypes = {
    address: PropTypes.string,
    title: PropTypes.string
};

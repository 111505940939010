import React from 'react';
import PropTypes from 'prop-types';

import TableEntries from './table-entries';

/*destructure props object*/
export default function Table ({array, title}){
    if ( array !== undefined && array !== null && array.length > 0 ) {
	/*return a React element*/
	return (
            <>
		<p>Table of {array.length}&nbsp;{title}</p>
		<table>
		<thead>
		    <tr>
			<th>id</th>
			<th>name</th>
			<th>lon</th>
			<th>lat</th>
		    </tr>
		</thead>
		<tbody>
		    <TableEntries array={array} />
		</tbody>
		</table>
            </>
	);
    }else{
	return (
            <>
		<h1>Table of {title}</h1>
		<p>Table loading...</p>
            </>
	);
    }
};
Table.propTypes = {
    array: PropTypes.array,
    title: PropTypes.string
};

import React, { useState, useEffect } from 'react'
import { Link} from 'react-router-dom';

import { get } from '../utils/request';

export default function Home(){

    const [bikeRideCount, setBikeRideCount] = useState('loading...');
    const [busStopCount, setBusStopCount] = useState('loading...');
    const [chargingStationCount, setChargingStationCount] = useState('loading...');
    const [parkRideCount, setParkRideCount] = useState('loading...');
    const [pidCount, setPidCount] = useState('loading...');
    const [taxiCount, setTaxiCount] = useState('loading...');
    const [ticketMachineCount, setTicketMachineCount] = useState('loading...');
    const [ticketOfficeCount, setTicketOfficeCount] = useState('loading...');
    const [trainStationCount, setTrainStationCount] = useState('loading...');
    const [trainHaltCount, setTrainHaltCount] = useState('loading...');

    useEffect(() => {
	/*effect goes here*/
	const address = 'https://overpass-api.de/api/interpreter?data=[out:json][timeout:60];relation(4189512);map_to_area;';
	const area = '(area);out body center qt;';
	////console.log('StationProfile:useEffect() address: ' + address);/

	//bike ride
	get(address + 'nwr[amenity=bicycle_parking][bike_ride]["bike_ride"!="no"]' + area)
	    .then(data => {
		setBikeRideCount(data.elements.length);
	    });

	//bus stop
	get(address + 'nwr[highway=bus_stop]' + area)
	    .then(data => {
		setBusStopCount(data.elements.length);
	    });

	//charging station
	get(address + 'nwr[amenity=charging_station]' + area)
	    .then(data => {
		setChargingStationCount(data.elements.length);
	    });

	//park ride
	get(address + 'nwr["park_ride"!="no"]["park_ride"]' + area)
	    .then(data => {
		setParkRideCount(data.elements.length);
	    });

	//passenger information display
	get(address + 'nwr[passenger_information_display=yes]' + area)
	    .then(data => {
		setPidCount(data.elements.length);
	    });

	//taxi
	get(address + 'nwr[amenity=taxi]' + area)
	    .then(data => {
		setTaxiCount(data.elements.length);
	    });

	//ticket machines
	get(address + 'nwr[amenity=vending_machine][vending=public_transport_tickets]' + area)
	    .then(data => {
		setTicketMachineCount(data.elements.length);
	    });

	//ticket offices
	get(address + 'nwr[shop=ticket]["tickets:public_transport"!=no]' + area)
	    .then(data => {
		setTicketOfficeCount(data.elements.length);
	    });

	//train stations
	get(address + 'nwr[railway=station][usage!=tourism]' + area)
	    .then(data => {
		setTrainStationCount(data.elements.length);
	    });

	//train halts
	get(address + 'nwr[railway=halt][usage!=tourism]' + area)
	    .then(data => {
		setTrainHaltCount(data.elements.length);
	    });
    }, []);

    return (
	<>
            <table>
		<thead>
		    <tr>
			<th>count</th>
			<th>feature</th>
		    </tr>
		</thead>
		    <tbody>
			<tr
			    key='br'
			>
			    <td>
			    	<Link
				    to={'/bike-ride'}
				>
				    <button>
					{bikeRideCount}
				    </button>
				</Link>
			    </td>
			    <td>Bike and ride (B+R) stations
			    </td>
			</tr>
			<tr
			    key='bs'
			>
			    <td>
			    	<Link
				    to={'/bus-stop'}
				>
				    <button>
					{busStopCount}
				    </button>
				</Link>
			    </td>
			    <td>Bus stops
			    </td>
			</tr>
			<tr
			    key='charging-station'
			>
			    <td>
				<Link
				    to={'/charging-station'}
				>
				    <button>
					{chargingStationCount}
				    </button>
				</Link>
			    </td>
			    <td>Charging Stations
			    </td>
			</tr>
			<tr
			    key='pr'
			>
			    <td>
			    	<Link
				    to={'/park-ride'}
				>
				    <button>
					{parkRideCount}
				    </button>
				</Link>
			    </td>
			    <td>Park and ride (P+R) stations
			    </td>
			</tr>
			<tr
			    key='pid'
			>
			    <td>
				<Link
				    to={'/pid'}
				>
				    <button>
					{pidCount}
				    </button>
				</Link>
			    </td>
			    <td>Passenger Information Displays
			    </td>
			</tr>
			<tr
			    key='taxi'
			>
			    <td>
			    	<Link
				    to={'/taxi'}
				>
				    <button>
				    	{taxiCount}
				    </button>
				</Link>
			    </td>
			    <td>Taxi stations
			    </td>
			</tr>
			<tr
			    key='ticket-machine'
			>
			    <td>
			    	<Link
				    to={'/ticket-machine'}
				>
				    <button>
				    	{ticketMachineCount}
				    </button>
				</Link>
			    </td>
			    <td>Ticket machines
			    </td>
			</tr>
			<tr
			    key='ticket-office'
			>
			    <td>
			    	<Link
				    to={'/ticket-office'}
				>
				    <button>
				    	{ticketOfficeCount}
				    </button>
				</Link>
			    </td>
			    <td>Ticket offices
			    </td>
			</tr>
			<tr
			    key='train-station'
			>
			    <td>
			    	<Link
				    to={'/train-station'}
				>
				    <button>
				    	{trainStationCount}
				    </button>
				</Link>
			    </td>
			    <td>Train Stations
			    </td>
			</tr>
			<tr
			    key='train-halt'
			>
			    <td>
			    	<Link
				    to={'/train-halt'}
				>
				    <button>
				    	{trainHaltCount}
				    </button>
				</Link>
			    </td>
			    <td>Train Halts
			    </td>
			</tr>
		    </tbody>
	    </table>
	</>
    );
}

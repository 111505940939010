import React from 'react';
import PropTypes from 'prop-types';

import EntitiesMap from './map/entities-map';

/*destructure props object*/
export default function Map ({array, title}){
    if ( array !== undefined && array !== null && array.length > 0 ) {
	/*return a React element*/
	return (
            <>
		<p>Map of {array.length}&nbsp;{title}</p>
		<EntitiesMap entities={array} />
            </>
	);
    }else{
	return (
            <>
		<p>Loading...</p>
            </>
	);
    }
};
Map.propTypes = {
    array: PropTypes.array,
    title: PropTypes.string
};

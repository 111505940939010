import React from 'react';
import PropTypes from 'prop-types';

function TableEntries ({array}) {
    if ( array !== undefined && array !== null && array.length > 0 ) {
        //iterate over array
        return array.map((item, index) => {
			if( item.type === "node"){
            return (
		<tr
		    key={item.id}
		>
		    <td>{item.id}</td>
		    <td>{item.tags.name}</td>
		    <td>{item.lat}</td>
		    <td>{item.lon}</td>
		</tr>
	    );
			} else if( item.type === "way" || item.type === "relation") {
            return (
		<tr
		    key={item.id}
		>
		    <td>{item.id}</td>
		    <td>{item.tags.name}</td>
		    <td>{item.center.lat}</td>
		    <td>{item.center.lon}</td>
		</tr>
	    );
			} else {
			    console.error("entities-map: OSM type NOT known");
			}
        });
    }else{
	//data is empty
	return null;
    }
}
TableEntries.propTypes = {
    array: PropTypes.array
};
export default TableEntries;

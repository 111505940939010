import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { get } from '../utils/request';
//TODO adjust config usage
import config from '../utils/config';
import Map from './map';
import Table from './table';

/*destructure props object*/
export default function FetchOsm ({address, title}) {

    const [array, setArray] = useState([]);

    /*fetch array in a JavaScript function*/
    const fetch = async () => {
	if(config && address){
            try {
		/*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
		//console.log("Fetch:fetch(): address: " + address);
		const res = await get(address);
		//console.log("Fetch:fetch(): res.length: "+res.elements.length);
		setArray((array) => res.elements);
            } catch (err) {
		console.error('err.message: ' + err.message);
		setArray((array) => []);
            }
	}else{
	    //console.log("Fetch:fetch(): address: " + address);
            console.error('Fetch:fetch(): config or address NOT available');
	}
    };

    useEffect(() => {
	/*effect goes here*/
	fetch();
	/*use an empty dependency array to ensure the hook is running only once*/
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, []);


    if ( array !== undefined && array !== null && array.length > 0 ) {
	/*return a React element*/
	return (
            <>
		<Map
		    array={array}
		    title={title}
		/>
		<Table
		    array={array}
		    title={title}
		/>
            </>
	);
    }else{
	return (
            <>
	    <p>Loading...</p>
            </>
	);
    }
};
FetchOsm.propTypes = {
    address: PropTypes.string,
    title: PropTypes.string
};
